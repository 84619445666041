import React from "react";
import { withRouter } from "next/router";
import Link from "next/link";
import classNames from "classnames";

export const links = [
  { href: "/", label: "首页", id: "home" },
  { href: "/daily-share", label: "每日分享", id: "daily-share" },
  // { href: "/my-recommend", label: "好文推荐", id: "my-recommend" },

  { href: "/tools", label: "在线工具", id: "tools" },
  {
    href: "/github-rank",
    label: "GithubRank",
    id: "github-rank",
    target: "_blank",
  },
  {
    href: "https://mianshi.vwood.xyz",
    label: "前端面试题",
    id: "mianshi",
    target: "_blank",
  },
  // { href: "/tools/json", label: "JSON格式化", id: "tools/json" },
  // { href: "/tools/timestamp", label: "时间戳", id: "tools/timestamp" },
  // { href: "/tiny-code/create", label: "代码编辑", id: "tiny-code/create" },
  // {
  //   href: "https://let-hooks.vwood.xyz",
  //   label: "Hooks",
  //   id: "hooks",
  //   target: "_blank",
  // },
  // {
  //   href: "https://cv.vwood.xyz/",
  //   label: "在线简历",
  //   id: "cv",
  //   target: "_blank",
  // },
  // { href: "/poetries", label: "古诗词", id: "poetry" },
  { href: "/about", label: "关于", id: "about" },
];

function Nav({ router }) {
  return (
    <nav className="group rounded-full bg-gradient-to-b from-zinc-50/70 to-white/90 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur-md [--spotlight-color:rgb(236_252_203_/_0.6)] pointer-events-auto relative z-50 hidden md:block">
      <ul className="flex bg-transparent px-3 text-sm font-medium">
        {links.map(({ href, label, target }) => (
          <li key={href} value={target}>
            <Link
              passHref
              href={href}
              target={target}
              className={classNames(
                "relative block whitespace-nowrap px-3 py-2 transition",
                { "!text-link": router.asPath === href }
              )}
            >
              {label}
              {router.asPath === href ? (
                <span
                  className="absolute inset-x-1 -bottom-px h-px bg-gradient-to-r from-[#8d4fff]/0 via-[#8d4fff]/70 to-[#8d4fff]/0"
                  // style="transform: none; transform-origin: 50% 50% 0px; opacity: 1;"
                  style={{
                    transform: "none",
                    transformOrigin: "50% 50%",
                    opacity: 1,
                  }}
                ></span>
              ) : null}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default withRouter(Nav);
